(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("window"), require("fetch"), require("jsonld"));
	else if(typeof define === 'function' && define.amd)
		define(["window", "fetch", "jsonld"], factory);
	else if(typeof exports === 'object')
		exports["$rdf"] = factory(require("window"), require("fetch"), require("jsonld"));
	else
		root["$rdf"] = factory(root["window"], root["fetch"], root["jsonld"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__245__, __WEBPACK_EXTERNAL_MODULE__520__, __WEBPACK_EXTERNAL_MODULE__544__) {
return 